import { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";

import GiveawayBackground from "../src/img/GiveawayBackground.jpg"
import { GiveawaySection, BackgroundImage, TitleSection, PrizeSection, PrizeColumn, Prize, PrizeImg, PrizeText, PrizeDesc } from "./Components/Giveaway/GiveawayElements";
import GiveawaySegment from "./Components/Giveaway/GiveawaySegment";
import RoadmapSegment from "./Components/Roadmap/RoadmapSegment";
import Footer from "./Components/Footer/Footer";
import Team from "./Components/Team/Team";
import { Nav, NavbarContainer, WalletText } from "./Components/Nav/NavbarElements";
import { HeroContainer, HeroImage, HeroSupply, HeroText, SocialBtn, TwitterBtn, DiscordBtn, ConnectContainer, AboutArea } from "./Components/Hero/HeroElements";
import AboutSection from "./Components/About/About";
import { pink } from "@material-ui/core/colors";
import ConnectBG from "../src/img/hero/ConnectBG.png";
import MintBG from '../src/img/hero/MintBG.png';
import Header from '../src/img/hero/MM_WEBSITE.png'
import { AboutLink } from "./Components/Hero/HeroElements";
import AccordionC from "./Components/FAQ/AccordianElements";

const ConnectButton = styled(WalletDialogButton)`

`;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div`
height: 15%;
width: 15%;
background: transparent;
display: flex;
position: absolute;
left: 13%;
bottom: 19.7%;
`; // add your styles here

const MintButton = styled(Button)`
background: transparent;
height: 100%;
width: 100%;
box-shadow: none;


`; // add your styles here

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);
  

  return (
    <main>

    <HeroContainer>
    <NavbarContainer>
      <WalletText>

      {wallet && (
        <p>{shortenAddress(wallet.publicKey.toBase58() || "")}</p>
      )}

      {wallet && <p>{(balance || 0).toLocaleString()} SOL</p>}

      </WalletText>
        
      </NavbarContainer>

        <ConnectContainer>
       {!wallet ? <ConnectButton style={{background: "transparent", height: '100%', width: '100%', boxShadow: "none" }}>{wallet ? " " : " "}</ConnectButton> : ""}

        </ConnectContainer>

     
      <MintContainer>
    
        {wallet &&  <MintButton 
            style={{background: "transparent", boxShadow: 'none', zIndex: 3, fontSize: '0'}}
            disabled={isSoldOut || isMinting || !isActive}
            onClick={onMint}
            variant="contained"
          >
            {isSoldOut ? (
              " "
            ) : isActive ? (
              isMinting ? (
                <CircularProgress />
              ) : (
                " "
              )
            ) : (
              <Countdown
                
                date={startDate}
                // onMount={({ completed }) => completed && setIsActive(true)}
                onComplete={() => setIsActive(true)}
                renderer={renderCounter}
              />
            )}
          </MintButton>
}
            
      </MintContainer>

      <AboutLink to = "about" smooth={true} duration={500} spy={true} exact='true'/>

      <DiscordBtn>
        <SocialBtn href="http://discord.gg/QT8TX7QDt2"/>
      </DiscordBtn>

      <TwitterBtn>
  <SocialBtn href="https://twitter.com/magic_mushies"/>
</TwitterBtn>
    {!wallet ? <HeroImage src = {ConnectBG}/> : !isActive ? <HeroImage src = {Header}/> : <HeroImage src = {MintBG}/>} 
    </HeroContainer>

    <AboutSection/>

    <RoadmapSegment/>

    <GiveawaySegment/>

      <Team/>
    
      <AccordionC/>

      <Footer/>



      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default Home;
